import type { Breadcrumb } from '@sentry/types';

import { addBreadcrumb as sentryAddBreadcrumb } from '@sentry/browser';

import { developmentMode, getEnvBoolean } from '../ReactScriptHelper';

import { Logger } from '@egr/xbox/utils/errors/Logger';

const LOG_BREADCRUMB = getEnvBoolean('LOG_BREADCRUMB', developmentMode);

export function addBreadcrumb(breadcrumb: Breadcrumb): void {
    try {
        if (LOG_BREADCRUMB) {
            const { category, level, message, data } = breadcrumb;
            // Note: it's only debug code -> so ignore the eslint issue
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const args: Array<unknown> = [`[${category}] ${message}`, data].filter(Boolean);
            switch (level) {
                case 'error':
                case 'fatal':
                    Logger.error(...args);
                    break;
                case 'warning':
                    Logger.warn(...args);
                    break;
                default:
                    Logger.log(...args);
                    break;
            }
        }

        sentryAddBreadcrumb(breadcrumb);
    } catch (error) {
        Logger.error(error);
    }
}
