import * as Lockr from 'lockr';
import isArray from 'lodash/isArray';
import * as React from 'react';

import { getWCFRoomPath } from './utils/AppUtils';
import { Splashscreen, splashscreenManager } from './views/global/misc/Splashscreen';

import { isReactNativeApp } from '@egr/xbox/app-api/AppDetection';
import { layoutInformation } from '@egr/xbox/egr-gui-elements/Helper/Darkmode';
import { loadScript } from '@egr/xbox/utils/Helper';
import { DEBUG_LOG_CONSOLE, Logger } from '@egr/xbox/utils/errors/Logger';

import { joinPath } from '@easterngraphics/wcf/modules/utils/string';

let backgroundColor: string | undefined;

try {
    const value: string | undefined = Lockr.get('app-settings');

    /* eslint-disable-next-line no-restricted-properties */
    const data = value != null ? JSON.parse(value) : [];

    if (isArray(data)) {
        const settings = new Map<string, Record<string, string>>(data as Array<[string, Record<string, string>]>);
        switch (settings.get('appSettings')?.layoutMode) {
            case 'light':
                backgroundColor = '#FFFFFF';
                layoutInformation.updateState({ layoutMode: 'light' });
                break;
            case 'dark':
                backgroundColor = '#3A3A3A';
                layoutInformation.updateState({ layoutMode: 'dark' });
                break;
            default:
                break;
        }
    }
} catch (e) {
    console.warn(e);
}

export const AppLoader: React.FC = () => {
    const [ component, setComponent ] = React.useState<React.FunctionComponentElement<{}>|null>(null);

    React.useEffect(
        (): void => {
            void (async (): Promise<void> => {
                splashscreenManager.startProgress();

                if (DEBUG_LOG_CONSOLE && isReactNativeApp) {
                    const { nativeLog } = await import('@egr/xbox/app-api/AppAction');

                    Logger.addLogging((...args) => {
                        nativeLog(
                            'warn',
                            args.toString(),
                        );
                    }, 'warning');
                    Logger.addLogging((...args) => {
                        nativeLog(
                            'error',
                            args.toString(),
                        );
                    }, 'error');
                }

                // eslint-disable-next-line @typescript-eslint/dot-notation
                if (window['CSG'] == null) {
                    await loadScript(joinPath(getWCFRoomPath(''), 'libs', 'csg-js', 'csg.min.js'));
                }

                const { getApp } = await import('./renderApp');

                const RealApp: React.FC = await getApp();

                setComponent(React.createElement(RealApp));
            })();
        },
        [] // call only once
    );

    return (
    <React.Fragment>
        {component}
        <Splashscreen backgroundColor={backgroundColor}/>
    </React.Fragment>);
};