/**
 * enum for Sentry.SeverityLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
 * (enum Sentry.Severity is deprecated)
 */
export const enum ErrorLevel {
    Fatal = 'fatal',
    Error = 'error',
    Warning = 'warning',
    Log = 'log',
    Info = 'info',
    Debug = 'debug'
}