/**
 * Shallow copy object and remove undefined properties
 */
export function removeUndefinedValues<T extends Object>(obj: T): T {
    const copy: T = {...obj};
    for (const key of Object.keys(copy)) {
        if (copy[key] === undefined) {
            delete copy[key];
        }
    }

    return copy;
}