import type { MakeStylesHook} from '@egr/xbox/utils/tss-react';
import type * as React from 'react';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';

import { BoxSplashscreenImage } from './BoxSplashscreenImage';

import { ProgressManagerClass } from '@egr/xbox/progress-manager/ProgressManager';
import { makeStyles } from '@egr/xbox/utils/tss-react';

export const splashscreenManager: ProgressManagerClass = new ProgressManagerClass('splash');

interface SplashscreenProps {
    backgroundColor: string | undefined;
}

type SplashscreenClasses = 'content' | 'image' | 'footer';

const useSplashscreenStyles: MakeStylesHook<SplashscreenClasses> = makeStyles({ name: 'Splashscreen' })((theme) => ({
    content: {
        background: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        width: '100%',
    },
    image: {
        alignItems: 'center',
        display: 'flex',
        flexBasis: 'auto',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 1,
        justifyContent: 'center',
    },
    footer: {
        padding: '16px 0',
        textAlign: 'center',
    }
}));

export const Splashscreen: React.FC<SplashscreenProps> = observer((props) => {
    const { classes } = useSplashscreenStyles();

    if (!splashscreenManager.inProgress) {
        return null;
    }

    return (
        <Modal open={true} style={{zIndex: 2000}}>
            <div className={classes.content} style={{backgroundColor: props.backgroundColor}}>
                <div className={classes.image}>
                    <BoxSplashscreenImage/>
                </div>
                <div className={classes.footer}>
                    <Typography variant="caption">© EasternGraphics GmbH</Typography>
                </div>
            </div>
        </Modal>
    );
});