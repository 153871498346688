import { appInfo } from '@egr/xbox/app-api/AppInfo';
import { PUBLIC_URL } from '@egr/xbox/utils/ReactScriptHelper';

import { isNotNullOrEmpty, isNullOrEmpty, joinPath } from '@easterngraphics/wcf/modules/utils/string';

export function getWCFRoomPath(folder: string): string {
    const baseUrl: string = (
        appInfo != null &&
        (appInfo.app_caching?.active ?? false) &&
        isNotNullOrEmpty(appInfo?.app_caching?.app_scheme)
    ) ? appInfo.app_caching.app_scheme : PUBLIC_URL;

    if (isNullOrEmpty(folder)) {
        return joinPath(baseUrl, 'w-cf-room');
    }

    return joinPath(baseUrl,'w-cf-room', folder);
}