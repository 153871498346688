import type { BaseMessage } from './Communication';

import { Logger } from '../utils/errors/Logger';

type LogHandler = (msg: BaseMessage) => void;

const LogHandlers = new Set<LogHandler>();

/** @internal */
export function _log(msg: BaseMessage): void {
    for (const handler of LogHandlers) {
        try {
            handler(msg);
        } catch (error) {
            Logger.error(error);
        }
    }
}

export function registerLogHandler(handler: LogHandler): VoidFunction {
    LogHandlers.add(handler);

    return (): void => {
        LogHandlers.delete(handler);
    };
}
