import { useSyncExternalStore } from 'react';

type SyncExternalListener<Key> = (value: Array<Key>) => void;

export class SyncExternalStore<State> {
    private listeners: Array<SyncExternalListener<keyof State>> = [];

    /** this is a react hook, for state outside of React.FC use snapshot instead */
    public get state(): State {
        return this.hook();
    }

    /** in React.FC use state (hook) instead */
    public get snapshot(): State {
        return this.internalState;
    }

    constructor(protected internalState: State) {
        this.getSnapshot = this.getSnapshot.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.updateState = this.updateState.bind(this);
        this.emitChange = this.emitChange.bind(this);
    }

    public subscribe(listener: SyncExternalListener<keyof State>): () => void {
        this.listeners = [...this.listeners, listener];
        return () => {
            this.listeners = this.listeners.filter(l => l !== listener);
        };
    }

    public updateState(state: Partial<State>): void {
        this.internalState = {...this.internalState, ...state };
        this.emitChange(state);
    }

    protected getSnapshot(): State {
        return this.internalState;
    }

    protected emitChange(state: Partial<State>): void {
        for (const listener of this.listeners) {
            listener(Object.keys(state) as Array<(keyof State)>);
        }
    }

    protected hook = () => {
        return useSyncExternalStore<State>(this.subscribe, this.getSnapshot);
    };
}