import type * as React from 'react';

import { styled } from '@mui/material/styles';

import PlaceholderImage from '../../../layout/res/logo_with_text.svg';

const StyledDiv = styled('div')({
    width: 177,
    '& > img': {
        margin: 'auto',
        maxWidth: '100%',
    }
});

export const BoxSplashscreenImage = () => {
    return (
        <StyledDiv>
            <img src={PlaceholderImage}/>
        </StyledDiv>
    );
};